import Codemirror from 'codemirror';
import './Editor.css'
import React, { useEffect , useRef } from 'react'
import 'codemirror/theme/dracula.css'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/addon/edit/closetag'
import 'codemirror/addon/edit/closebrackets'
import 'codemirror/lib/codemirror.css'
import ACTIONS from '../Actions';

const Editor = ({ socketRef, roomId, onCodeChange }) => {

  const editorRef = useRef(null);
  useEffect(() => {
      async function init() {
          editorRef.current = Codemirror.fromTextArea(
              document.getElementById('realtimeEditor'),
              {
                  mode: { name: 'javascript', json: true },
                  theme: 'dracula',
                  autoCloseTags: true,
                  autoCloseBrackets: true,
                  lineNumbers: true,
              }
          );

          editorRef.current.on('change', (instance, changes) => {
              const { origin } = changes;
              const code = instance.getValue();
              onCodeChange(code);
              if (origin !== 'setValue') {
                  socketRef.current.emit(ACTIONS.CODE_CHANGE, {
                      roomId,
                      code,
                  });
              }
          });
      }
      init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
      if (socketRef.current) {
          socketRef.current.on(ACTIONS.CODE_CHANGE, ({ code }) => {
              if (code !== null) {
                  editorRef.current.setValue(code);
              }
          });
      }

      return () => {
          socketRef.current.off(ACTIONS.CODE_CHANGE);
      };
  }, [socketRef.current]);

  return <textarea id="realtimeEditor"></textarea>;
};

export default Editor
